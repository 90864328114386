// Page2.js
import React from 'react';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';

function Contact() {
  return (
    <>
      <Navbar/>
      <section id="notfound" className="notfound section-bg">

      <div className="container">
        <div className="row">

        <div className="col-1"></div>
        <div className="col-5">
        <h1>Contactez-nous</h1>

            <p>Nous sommes à votre écoute pour toute question ou prise de rendez-vous. Notre équipe de spécialistes en psychopédagogie est dédiée à vous fournir le soutien et l'accompagnement nécessaires pour surmonter les défis d'apprentissage et de développement personnel.</p>

            <h2>Coordonnées :</h2>
              <p>Téléphone : <a href="tel:[0771814407]">07 71 81 44 07</a></p>
              <p>Email : <a href="mailto:mezzipsychopeda@gmail.com">mezzipsychopeda@gmail.com</a></p>
              <p>Adresse : Les Pavillons-sous-Bois 93320</p>

          </div>
          <div className="col"></div>
          <div className="col-5">
            <h2>Nos services incluent :</h2>
              <ul>
                  <li>Évaluation et intervention psychopédagogique</li>
                  <li>Soutien aux troubles de l'apprentissage</li>
                  <li>Accompagnement des enfants et des adolescents</li>
                  <li>Stratégies de motivation et gestion de l’attention</li>
                  <li>Conseils aux parents et enseignants</li>
              </ul>

              <h2>N'hésitez pas à nous contacter pour :</h2>
              <ul>
                  <li>Obtenir des informations sur nos services</li>
                  <li>Prendre rendez-vous pour une consultation</li>
                  <li>Poser toute question relative à nos interventions</li>
              </ul>

            
          </div>
        </div>
      </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;