import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer id="footer" className="top-space">
        <div className="footer1">
          <div className="container">
            <div className="row">
              <div className="col-md-3 widget">
                <h3 className="widget-title">Contact</h3>
                <div className="widget-body">
                  <p>
                    <a href="mailto:mezzipsychopeda@gmail.com">mezzipsychopeda@gmail.com</a><br />
                    <br />
                    Les Pavillons-sous-Bois 93320
                  </p>
                </div>
              </div>

              <div className="col-md-3 widget">
                <h3 className="widget-title">Suivez nous</h3>
                <div className="widget-body">
                  <p className="follow-me-icons">
                    <a href="https://www.facebook.com/mezzipsychopeda" rel="noopener noreferrer" target="_blank" aria-label="Facebook"><i className="fa fa-facebook fa-2"></i></a>
                    <a href="http://linkedin.com/in/mezzipsychopeda171281" rel="noopener noreferrer" target="_blank" aria-label="Linkedin"><i className="fa fa-linkedin fa-2"></i></a>
                  </p>
                </div>
              </div>

              <div className="col-md-6 widget">
                <h3 className="widget-title">Notre cabinet de Psychopédagogie</h3>
                <div className="widget-body">
                  <p>
                    Bienvenue dans notre Cabinet de Psychopédagogie, un espace dédié à l'accompagnement personnalisé des enfants, adolescents et adultes. Nous offrons des évaluations, un soutien psychopédagogique et des ateliers de développement personnel pour favoriser l'épanouissement et la réussite scolaire. Notre équipe de professionnels qualifiés met en œuvre des stratégies adaptées à chaque individu. Contactez-nous pour un rendez-vous et découvrez comment nous pouvons vous aider à réaliser votre plein potentiel.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer2">
          <div className="container">
            <div className="row">
              <div className="col-md-6 widget">
                <div className="widget-body">
                  <p className="simplenav">
                    <Link to="/">Home</Link> | 
                    <Link to="/parcours">Parcours</Link> |
                    <Link to="/activite">Activité</Link> |
                    <Link to="/contact">Contact</Link>
                  </p>
                </div>
              </div>

              <div className="col-md-6 widget">
                <div className="widget-body">
                  <p className="text-right">
                    Copyright &copy; 2024, SISENT. Réalisez votre site web avec notre équipe <a target="_blank" href="https://sisent.fr" rel="noreferrer"> https://sisent.fr </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
